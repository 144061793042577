<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal_contables">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar Cecos/Afes</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalContable"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <h5
                  class="text-center"
                  v-if="tifContables.length > 0"
                  :class="
                    totalPresupuesto == 100 ? 'text-success' : 'text-danger'
                  "
                >
                  Porcentaje Total {{ totalPresupuesto }}%
                </h5>
              </div>
              <div class="col-md-1">
                <button
                  v-if="totalPresupuesto < 100"
                  type="button"
                  class="btn btn-sm bg-primary float-right"
                  @click="addContable()"
                  style="cursor: pointer"
                  :disabled="estado_contable != -1"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            class="modal-body pt-0"
            v-if="tifContables.length > 0 || estado_contable == 1"
          >
            <table
              class="table table-bordered table-striped table-hover table-sm text-xs mb-0"
            >
              <thead class="bg-dark text-center">
                <tr class="text-nowarp">
                  <th>Acciones</th>
                  <th>OPEX/CAPEX</th>
                  <th>CECO/AFE/G&A</th>
                  <th style="width: 8em">%</th>
                </tr>
              </thead>
              <tbody id="tbody">
                <tr
                  v-for="(contable, index) in tifContables"
                  :key="contable.id"
                >
                  <td class="text-center p-1">
                    <div class="btn-group" v-if="estado_contable != 2">
                      <button
                        type="button"
                        class="btn bg-navy"
                        :disabled="
                          estado_contable != -1 || tifContable.estado == 2
                        "
                        @click="editContable(index)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn bg-danger"
                        :disabled="
                          estado_contable != -1 || tifContable.estado == 2
                        "
                        @click="destroy(index, contable)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <div
                      class="btn-group"
                      v-if="
                        estado_contable == 2 && id_actualizar_contable == index
                      "
                    >
                      <button
                        type="button"
                        class="btn bg-success"
                        v-if="!$v.tifContable.$invalid"
                        @click="saveUpdateContable(index)"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button class="btn bg-danger" @click="cancelContable()">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                    <div
                      class="btn-group"
                      v-if="
                        estado_contable == 2 && id_actualizar_contable !== index
                      "
                    >
                      <button type="button" class="btn bg-navy" disabled>
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn bg-danger" disabled>
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  <td class="text-center p-1">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <select
                        id="tipo_cargo"
                        class="form-control form-control-sm col-md-12"
                        v-model="tifContable.tipo_cargo_obj"
                        :class="
                          $v.tifContable.tipo_cargo_obj.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="getTipoCargo()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="opex in listasForms.opex_capex"
                          :key="opex.numeracion"
                          :value="opex"
                        >
                          {{ opex.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          contable.tipo_cargo_obj !== undefined &&
                          contable.tipo_cargo_obj !== null
                        "
                      >
                        {{ contable.tipo_cargo_obj.descripcion }}
                      </div>
                      <div v-else>
                        {{ contable.opexCapex }}
                      </div>
                    </div>
                  </td>
                  <td class="p-1">
                    <div
                      class="row"
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <div class="col-md-5" v-if="tifContable.tipo_cargo_obj">
                        <select
                          v-model="tifContable.tipo_presupuesto"
                          placeholder="Ceco AFE"
                          label="nombres"
                          class="form-control form-control-sm p-0"
                          :class="
                            $v.tifContable.tipo_presupuesto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="asignarCecosAfes()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="lista in listasForms.lista_cecos_afes"
                            :key="lista.numeracion"
                            :value="lista.numeracion"
                          >
                            {{ lista.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-7" v-if="tifContable.tipo_presupuesto">
                        <v-select
                          v-model="tifContable.cecosAfes_obj"
                          :class="[
                            $v.tifContable.cecosAfes_obj.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          class="form-control form-control-sm p-0"
                          v-if="tifContable.tipo_presupuesto"
                          @input="[$v.tifContable.cecosAfes_obj.$touch]"
                          placeholder="CeCo / AFE"
                          label="codigo_contable"
                          :options="listasForms.cecos_afes"
                        ></v-select>
                      </div>
                    </div>

                    <div v-else>
                      <div class="row">
                        <div class="col-md-4 text-center">
                          <span
                            class="badge"
                            :class="
                              contable.tipo_presupuesto == 1
                                ? 'bg-info'
                                : 'bg-navy'
                            "
                          >
                            {{
                              contable.tipo_cargo_obj
                                ? contable.tipo_cargo_obj.cod_alterno
                                : ""
                            }}
                          </span>
                        </div>
                        <div
                          class="col-md-8"
                          v-if="
                            contable.cecosAfes_obj !== undefined &&
                            contable.cecosAfes_obj !== null
                          "
                        >
                          <input
                            :value="contable.cecosAfes_obj.codigo_contable"
                            type="text"
                            class="form-control form-control-sm"
                            style="font-size: 0.85em"
                            disabled
                          />
                        </div>
                        <div class="col-md-8" v-else>
                          <!--                           <input
                              :value="contable.ceco_afe.codigo_contable"
                              type="text"
                              class="form-control form-control-sm"
                              style="font-size: 0.85em"
                              disabled
                            /> -->
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center p-1">
                    <div
                      v-if="
                        id_actualizar_contable == index && estado_contable == 2
                      "
                    >
                      <input
                        v-model="tifContable.porcentaje"
                        type="number"
                        class="form-control form-control-sm"
                        id="porcentaje"
                        :class="
                          $v.tifContable.porcentaje.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div v-else>
                      {{ contable.porcentaje }}
                    </div>
                  </td>
                </tr>
                <tr v-if="estado_contable == 1">
                  <td class="text-center">
                    <div class="btn-group">
                      <button
                        class="btn bg-success"
                        v-if="!$v.tifContable.$invalid"
                        @click="saveContable()"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button class="btn bg-danger" @click="cancelContable()">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    <select
                      id="tipo_cargo"
                      class="form-control form-control-sm col-md-12"
                      v-model="tifContable.tipo_cargo_obj"
                      :class="
                        $v.tifContable.tipo_cargo_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @change="
                        [$v.tifContable.tipo_cargo_obj.$touch, getTipoCargo()]
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="opex in listasForms.opex_capex"
                        :key="opex.numeracion"
                        :value="opex"
                      >
                        {{ opex.descripcion }}
                      </option>
                    </select>
                    <div
                      v-if="
                        (!$v.tifContable.tipo_cargo_obj.$dirty ||
                          $v.tifContable.tipo_cargo_obj.$invalid) &&
                        (tifContable.tipo_cargo_obj == '' ||
                          tifContable.tipo_cargo_obj == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                  <td>
                    <div class="row" v-if="tifContable.tipo_cargo_obj">
                      <div
                        class="col-md-5"
                        v-if="listasForms.lista_cecos_afes.length > 0"
                      >
                        <select
                          v-model="tifContable.tipo_presupuesto"
                          placeholder="Ceco AFE"
                          label="nombres"
                          class="form-control form-control-sm"
                          :class="
                            $v.tifContable.tipo_presupuesto.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="
                            [
                              $v.tifContable.tipo_presupuesto.$touch,
                              asignarCecosAfes(),
                            ]
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="lista in listasForms.lista_cecos_afes"
                            :key="lista.numeracion"
                            :value="lista.numeracion"
                          >
                            {{ lista.descripcion }}
                          </option>
                        </select>
                        <div
                          v-if="
                            (!$v.tifContable.tipo_presupuesto.$dirty ||
                              $v.tifContable.tipo_presupuesto.$invalid) &&
                            (tifContable.tipo_presupuesto == '' ||
                              tifContable.tipo_presupuesto == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </div>
                      <div class="col-md-7" v-if="tifContable.tipo_presupuesto">
                        <v-select
                          v-model="tifContable.cecosAfes_obj"
                          :class="[
                            $v.tifContable.cecosAfes_obj.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          class="form-control form-control-sm p-0"
                          v-if="tifContable.tipo_presupuesto"
                          @input="[$v.tifContable.cecosAfes_obj.$touch]"
                          placeholder="CeCo / AFE"
                          label="codigo_contable"
                          :options="listasForms.cecos_afes"
                        ></v-select>
                        <div
                          v-if="
                            (!$v.tifContable.cecosAfes_obj.$dirty ||
                              $v.tifContable.cecosAfes_obj.$invalid) &&
                            (tifContable.cecosAfes_obj == '' ||
                              tifContable.cecosAfes_obj == null)
                          "
                        >
                          <span class="text-danger"
                            ><small>*Campo requerido</small></span
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="porcentaje"
                      v-model="tifContable.porcentaje"
                      v-on:input="$v.tifContable.porcentaje.$touch"
                      :class="
                        $v.tifContable.porcentaje.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <div
                      v-if="
                        (!$v.tifContable.porcentaje.$dirty ||
                          $v.tifContable.porcentaje.$invalid) &&
                        (tifContable.porcentaje == '' ||
                          tifContable.porcentaje == null)
                      "
                    >
                      <span class="text-danger"
                        ><small>*Campo requerido</small></span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('tif.asignacionesContables.edit') &&
              totalPresupuesto == 100 &&
              estado_contable == -1
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "TifAsignacionContableCecoAfe",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      tifContables: [],
      id: null,
      tifContable: {},
      tif_liquidacion_id: null,
      listasForms: {
        areas: [],
        opex_capex: [],
        responsables: [],
        lista_cecos_afes: [],
        cecos_afes: [],
        activos_produccion: [],
      },
      totalPresupuesto: 0,
      estado_contable: -1,
      id_actualizar_contable: -1,
    };
  },

  validations: {
    tifContable: {
      tipo_cargo_obj: {
        required,
      },
      tipo_presupuesto: {
        required,
      },
      porcentaje: {
        required,
      },
      cecosAfes_obj: {
        required,
      },
    },
  },

  methods: {
    async getDatosModal(liquidacion) {
      this.$parent.cargando = true;
      this.tifContables = [];
      this.tif_liquidacion_id = liquidacion.id;
      if (liquidacion.asignacion_contable.length > 0) {
        this.tifContables = [...liquidacion.asignacion_contable];
        // Se asignan los valores para el formulario
        await this.tifContables.forEach(async (liq) => {
          this.getCecosAfes(liq.tipo_presupuesto);
          liq.cecosAfes_obj = this.listasForms.cecos_afes.find(
            (cf) => cf.id == liq.ceco_afe_id
          );
          liq.tipo_cargo_obj = this.listasForms.opex_capex.find(
            (coax) => coax.numeracion == liq.tipo_presupuesto
          );
        });
      }

      this.totalPresupuesto = 0;
      (this.estado_contable = -1), this.calcularContable();
      this.$parent.cargando = false;
    },

    /******* Funciones para las listas */
    getOpexCapex() {
      axios.get("/api/lista/132").then((response) => {
        this.listasForms.opex_capex = response.data;
      });
    },

    getResponsables() {
      axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.responsables = response.data;
      });
    },

    async getCecosAfes(numeracion) {
      this.$parent.cargando = true;
      await axios
        .get("/api/lista/151", {
          params: {
            in_number: [numeracion],
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.listasForms.lista_cecos_afes = response.data;
        });
    },

    async asignarCecosAfes(tipoP = null) {
      this.listasForms.cecos_afes = [];
      if (tipoP == null) {
        if (this.tifContable.tipo_presupuesto == "") {
          this.tifContable.tipo_cargo_obj = null;
        }
        tipoP = this.tifContable.tipo_presupuesto;
      }
      this.$parent.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
          params: {
            tipo_presupuesto: tipoP,
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.listasForms.cecos_afes = response.data;
        });
    },
    /******* Fin - Funciones para las listas */

    addContable() {
      this.tifContable = {
        id: null,
        tif_liquidacion_id: this.tif_liquidacion_id,
        tipo_cargo: null,
        tipo_cargo_obj: null,
        tipo_presupuesto: null,
        cecosAfes_obj: null,
        ceco_afe_id: null,
        porcentaje: null,
      };
      this.estado_contable = 1;
    },

    async editContable(index) {
      await this.getCecosAfes(this.tifContables[index].tipo_presupuesto);
      this.id_actualizar_contable = index;
      this.estado_contable = 2;
      this.tifContable = { ...this.tifContables[index] };
      await this.getCecosAfes(this.tifContable.tipo_presupuesto);
      await this.asignarCecosAfes();
    },

    async saveContable() {
      let me = this;
      await me.calcularContable();
      if (
        me.totalPresupuesto + parseFloat(me.tifContable.porcentaje) <= 100 &&
        me.tifContable.porcentaje > 0
      ) {
        me.tifContable.tipo_cargo = me.tifContable.tipo_cargo_obj.numeracion;
        me.tifContable.ceco_afe_id = me.tifContable.cecosAfes_obj.id;
        me.tifContables.push(me.tifContable);
        me.tifContable = {};
        me.estado_contable = -1;
        await me.calcularContable();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error, el porcentaje no puede ser mayor a 100",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async saveUpdateContable(index) {
      let me = this;
      if (
        me.totalPresupuesto -
          this.tifContables[index].porcentaje +
          parseFloat(me.tifContable.porcentaje) <=
          100 &&
        me.tifContable.porcentaje > 0
      ) {
        me.tifContable.tipo_cargo = me.tifContable.tipo_cargo_obj.numeracion;
        me.tifContable.ceco_afe_id = me.tifContable.cecosAfes_obj.id;
        this.tifContables[index] = this.tifContable;
        this.estado_contable = -1;
        this.id_actualizar_contable = -1;
        await me.calcularContable();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error, el porcentaje no puede ser mayor a 100",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    cancelContable() {
      this.estado_contable = -1;
      this.tifContable = {};
    },

    getTipoCargo() {
      this.listasForms.lista_cecos_afes = [];
      this.getCecosAfes(this.tifContable.tipo_cargo_obj.numeracion);
      this.tifContable.tipo_presupuesto = null;
      this.tifContable.cecosAfes_obj = null;
    },

    calcularContable() {
      let totalPresupuesto = 0;
      this.tifContables.forEach((element) => {
        totalPresupuesto += parseFloat(element.porcentaje);
      });
      this.totalPresupuesto = totalPresupuesto;
      return totalPresupuesto;
    },

    save() {
      if (this.tifContables.length > 0 && this.totalPresupuesto == 100) {
        this.$parent.cargando = true;
        const form = {
          data: this.tifContables,
        };

        axios({
          method: "PUT",
          url: "/api/tif/asignacionesContables/detalles",
          data: form,
        })
          .then(() => {
            this.$parent.cargando = false;
            this.$refs.closeModalContable.click();
            this.$parent.getIndex();

            this.$swal({
              icon: "success",
              title: "La asignación contable se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: `No es posible realizar la asignación contable con la información diligenciada.`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async destroy(index, contable = null) {
      await this.$swal({
        title: "Está seguro de eliminar esta asignación: " + "?",
        text:
          "Los cambios no se pueden revertir y recuerde que debe crear una nueva asignación ya que el % de la operacion queda en " +
          (parseFloat(this.totalPresupuesto) -
            parseFloat(contable.porcentaje)) +
          "%",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          this.tifContables.splice(index, 1);
          this.addContable();
          this.calcularContable();
          this.estado_contable = -1;
          this.$swal({
            icon: "success",
            title: "Se eliminó el CECO/AFE exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        }
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    getActivoProduccion() {
      axios.get("/api/lista/137").then((response) => {
        this.listasForms.activos_produccion = response.data;
      });
    },
  },

  beforeMount() {
    this.getAreas();
    this.getOpexCapex();
    this.getResponsables();
    this.getActivoProduccion();
    this.asignarCecosAfes();
  },
};
</script>